<template>
  <div
    v-if="
      globals[0].isLaunchDate &&
      (msTillLaunchTime === -1 || msTillLaunchTime !== 0)
    "
  >
    <template v-if="msTillLaunchTime !== -1">
      <div class="flex flex-col font-bold text-navbar-text">
        <span
          class="flex justify-center text-[0.6rem] sm:text-sm leading-tight sm:leading-snug tracking-widest"
          :class="[scrolled ? 'hidden' : 'visible']"
        >
          LAUNCHING IN
        </span>
        <span class="flex justify-center space-x-[0.4rem]">
          <span
            v-for="(unit, index) in timeUnits"
            :key="index"
            class="flex flex-row space-x-[0.4rem]"
          >
            <span
              class="flex flex-col items-center justify-center tabular-nums text-[0.9rem] sm:text-xl leading-tight sm:leading-none"
            >
              {{ unit.value }}
              <span class="text-[0.4rem] sm:text-[0.6rem]">{{
                unit.label
              }}</span>
            </span>
            <span
              v-if="index < timeUnits.length - 1"
              class="flex flex-col items-center justify-center text-[0.9rem] sm:text-xl leading-tight sm:leading-none"
              >:<span class="text-[0.4rem] sm:text-[0.6rem]">&nbsp;</span>
            </span>
          </span>
        </span>
      </div>
    </template>

    <div v-else>
      <TimerMessage />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TimerMessage from "./TimerMessage.vue";

export default {
  name: "TimerLaunch",
  components: { TimerMessage },
  props: {
    msTillLaunchTime: {
      type: Number,
    },
    scrolled: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      globals: "globals",
    }),
    day() {
      let d = Math.floor(this.msTillLaunchTime / 1000 / 60 / 60 / 24);
      return d > 9 ? d : "0" + d;
    },
    hour() {
      let h = Math.floor(this.msTillLaunchTime / 1000 / 60 / 60) % 24;
      return h > 9 ? h : "0" + h;
    },
    min() {
      let m = Math.floor(this.msTillLaunchTime / 1000 / 60) % 60;
      return m > 9 ? m : "0" + m;
    },
    sec() {
      let s = Math.floor(this.msTillLaunchTime / 1000) % 60;
      return s > 9 ? s : "0" + s;
    },
    timeUnits() {
      return [
        { value: this.day, label: "DAYS" },
        { value: this.hour, label: "HRS" },
        { value: this.min, label: "MINS" },
        { value: this.sec, label: "SECS" },
      ];
    },
  },
};
</script>
